import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-grid p-formgrid p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DField = _resolveComponent("DField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formSpec.formDef, (field, i) => {
      return (_openBlock(), _createBlock(_component_DField, {
        key: i,
        formDef: field
      }, null, 8, ["formDef"]))
    }), 128))
  ]))
}