
import { Options, Vue } from "vue-class-component";
import DForm from "@/components/dynamic/DForm.vue";
import InputText from "primevue/inputtext";
import { namespace } from "vuex-class";

const formProvider = namespace("formProvider");

@Options({ components: { DForm, InputText } })
export default class DFormsTest extends Vue {
  value = "ahoj";
  created() {
    this.fetchForm();
  }

  @formProvider.Action
  fetchForm!: () => void;
}
